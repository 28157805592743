import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Section from "../Section"
import { Grid, Tile, Wrapper } from "../UI/Grid"
import Separator from "../Separator"
import { Heading } from "../UI/Typography"
import { List } from "../UI/List"
import Paragraph from "../Paragraph"
import EnvelopeIcon from "../Icons/EnvelopeIcon"

import { theme } from "../../utils/styled_config"

const CareersSection = () => {
  const careers = useStaticQuery(graphql`
    query CareersQuery {
      allCareersJson {
        nodes {
          id
          job_title
          requirements
        }
      }
    }
  `)

  return (
    <Section>
      <Wrapper>
        <Grid justifyContent="center">
          <Tile lg={10} textAlign="center">
            <Heading fontSize="h2" mb={5}>
              Choose a career you want to pursue.
            </Heading>
            <Paragraph mb={10}>
              We are expanding our team and in need of dynamic, curious and
              brave individuals for the following positions:
            </Paragraph>
          </Tile>
        </Grid>

        <Grid justifyContent="center">
          <Tile lg={10}>
            {careers && (
              <Grid>
                {careers.allCareersJson.nodes.map(c => (
                  <Tile lg={12} key={c.id} mb={{ _: 6, lg: 10 }}>
                    <Heading fontSize="h3" mb={4}>
                      {c.job_title}
                    </Heading>
                    <List listStyle="disc" lineHeight={1.8}>
                      {c.requirements.map((r, idx) => (
                        <li key={idx}>{r}</li>
                      ))}
                    </List>
                  </Tile>
                ))}
              </Grid>
            )}
          </Tile>
        </Grid>
        <Separator />
        <ContactRow>
          <EnvelopeIcon />
          <span>Send your resume to <a href="mailto:ea@tajarahospitality.com" style={{"color":"#ffffff"}}>ea@tajarahospitality.com</a></span>
        </ContactRow>
      </Wrapper>
    </Section>
  )
}


const ContactRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 30px;
  .icon {
    color: ${theme.color.brand};
    font-size: 24px;
    margin-right: 12px;
    margin-top: 2px;
  }
`

export default CareersSection
