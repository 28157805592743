import styled, { css } from "styled-components"
import { space, typography } from "styled-system"

export const List = styled.ul`
  ${p =>
    p.listStyle === "none" &&
    css`
      list-style: none;
      padding: 0;
      margin: 0;
    `}
  ${p =>
    p.listStyle === "disc" &&
    css`
      list-style: disc;
    `}

  ${p =>
    p.inline &&
    css`
      li {
        display: inline-block;

        & + li {
        }
      }
    `}
  ${typography};
  ${space};
`

export const ListItem = styled.li`
  ${space};
`
