import React from "react"
import Hero from "../Hero"
import HeroImg from "../../images/hero-careers.jpg"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import Title from "../Title"
import styled from "styled-components"
import Element from "../UI/Element"
import LineVector from "../LineVector"

const CareersHero = () => {
  return (
    <Hero img={HeroImg} page="inner">
      <Container>
        <Row>
          <Col col={10} xl={4}>
            <TitleStyled size="d4" lg="d1">
              Be part of our growing team.
            </TitleStyled>
            <Element textAlign="right">
              <LineVector height={13} width={239} />
            </Element>
          </Col>
        </Row>
      </Container>
    </Hero>
  )
}

const TitleStyled = styled(Title)`
  line-height: 1;
`

export default CareersHero
