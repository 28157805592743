import styled from "styled-components"
import { space, typography, layout, color } from "styled-system"

const Element = styled.div`
  ${space};
  ${typography};
  ${layout};
  ${color};
`

export default Element
