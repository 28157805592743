import React from "react"
import BlurredSection from "../components/BlurredSection"
import Seo from "../components/seo"
import CareersHero from "../components/careers/CareersHero"
import CareersSection from "../components/careers/CareersSection"

const Careers = () => {
  return (
    <>
      <Seo title="Careers" />
      <BlurredSection>
        <CareersHero />
        <CareersSection />
      </BlurredSection>
    </>
  )
}

export default Careers
