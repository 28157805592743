import styled from "styled-components"
import { space, typography } from "styled-system"
import theme from "../../utils/theme"

export const Heading = styled.div`
  font-family: ${theme.fontFamilies.heading};
  font-size: ${`${theme.fontSizes.h1}px`};
  letter-spacing: -0.02em;
  line-height: 1;

  ${space};
  ${typography};
`
